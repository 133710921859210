import path from 'path';
import PagePost from '../models/PagePost';
import PropTypes from 'prop-types';
import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import { terms as pageMeta } from '../data/page_meta.yml';
import { siteMeta } from '../data/site_meta.yml';
import PageWrapper from '../components/DefaultPageWrapper';
import {
    Link,
    SEO,
} from '@brandedholdings/react-components';

class TermsOfUse extends React.Component {
    getChildContext() {
        return { siteMeta };
    }

    render() {
        const page = this.props;
        pageMeta.link = page.location.pathname;
        const post = new PagePost(pageMeta, siteMeta);

        const pageImages = {};
        page.data.pageImages.edges.forEach(({ node }) => pageImages[path.basename(node.absolutePath).split('.')[0]] = node);

        return (
            <PageWrapper>
                <SEO post={ post } />
                <BackgroundImage
                    className="background__gatsby background background__graphic"
                    fluid={pageImages.hero.image.fluid}
                >
                    <div className="layout">
                        <div className="layout-content center">
                            <h1>Terms &amp; Conditions of Use</h1>
                        </div>
                    </div>
                </BackgroundImage>
                <div className="background background__white">
                    <div className="layout">
                        <div className="layout-content">
                            <h3>Effective: October 1, 2018</h3>
                            <p>
                                <em>Updated May 18, 2021</em>
                            </p>
                            <p>By accessing the {siteMeta.nameShort} website (the “<strong>Website</strong>”), you agree to be bound by these Terms and Conditions (the “<strong>Terms</strong>”). Please read these Terms carefully. If you do not agree to these Terms, please exit the Website immediately.</p>

                            <p>By access and using the Site, you Accept and Agree, without limitation to the following:</p>

                            <p>We reserve the right, at any time, to modify, alter, or update the terms and conditions of this Agreement without prior notice. Modifications shall become effective immediately upon being posted on the Site. Your continued use of the Site after amendments are posted constitutes an acknowledgement and acceptance of this agreement and its modifications. Except as provided in this paragraph, this agreement may not be amended.</p>

                            <h4>Loan Form Submitting Service</h4>
                            <p>Our loan form submitting service (the “<strong>Service</strong>”) connects consumers with prospective lenders participating in our network (the “<strong>Lender Partners</strong>”). To use the Service, you must complete an <Link to="/form/">Online Request Form</Link> (a “<strong>Request Form</strong>”) that requests personal information from you, including your name, address, telephone number, email address, social security number, bank account information, and employment information. Our collection and use of this information is subject to the Website’s <Link to="/privacy/">Privacy Policy</Link>, which is incorporated into these Terms.</p>

                            <span id="credit-disclosure" className="anchor-offset" />
                            <h4>Credit Disclosure</h4>
                            <p>You expressly acknowledge and agree that lending partners who review the information you submit may obtain credit checks, consumer credit reports and other personal data from Experian, Equifax, Trans Union or through alternative providers. Such information may be used by a lending partner, as permitted by law, in order to (i) authenticate and verify your identity based on the information you provide; (ii) assess your eligibility for credit and identify products and/or services which may be available to you; (iii) determine your debt to income ratio; (iv) obtain a credit score.</p>

                            <span id="electronic-disclosure" className="anchor-offset" />
                            <h4>Electronic Disclosure</h4>

                            <p>By Submitting your <Link to="/form/">Request Form</Link> you agree to receive notifications, disclosures, and other documents and communications both from {siteMeta.nameShort} and from our Lender Partners. If you are connected to a Lender Partner, that lender may require you to execute an electronic loan contract. This electronic loan contract will be as binding as a loan contract in paper form. And your electronic signature will be as valid as a physical signature on a paper loan contract.</p>

                            <p>The lender may also require you to consent to receiving all notifications regarding your loan electronically. These electronic communications may include attempts to collect a debt.</p>

                            <p>Additionally, the lender may require you to access documents related to a loan, including your loan contract. By Submitting your <Link to="/form/">Request Form</Link>  you agree to this form of access.</p>

                            <p>The lender may allow you to withdraw your consent to electronic disclosures. Please contact the lender with whom you were connected to learn more about this process. Please note, however, that not consenting to receive electronic disclosures may affect your ability to receive a loan from a Lender Partner.</p>

                            <h3>Accuracy</h3>
                            <p>You represent that the information you provide to {siteMeta.nameShort}, and ultimately, the Lender Partners, through your <Link to="/form/">Request Form</Link>  is accurate and truthful. If the information in your <Link to="/form/">Request Form</Link>  is not accurate and truthful, {siteMeta.nameShort} may deny, suspend, or terminate your use of the Service.</p>

                            <h3>Control Over Features, Functions, and Access to the Service</h3>
                            <p>{siteMeta.nameShort} may change any information, features, or functions of the Service without prior notice. {siteMeta.nameShort} may deny you access to all or part of the Service without prior notice if you engage in any conduct or activities that {siteMeta.nameShort} determines, in its sole discretion, violate these Terms, the rights of {siteMeta.nameShort} or any third party, or is otherwise inappropriate. {siteMeta.nameShort} is not responsible for any errors or delays in providing Service caused by errors in the <Link to="/form/">Request Form</Link>  information provided by you or by any technical problems beyond its reasonable control.</p>

                            <h3>Disclaimers and Limitations</h3>
                            <p>THE WEBSITE AND THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES ASSOCIATED WITH IT ARE PROVIDED “<strong>AS IS.</strong>” {siteMeta.nameShort} EXPRESSLY DISCLAIMS ANY WARRANTY OF ANY KIND, WHETHER EXPRESS OR IMPLIED, AS TO ANY MATTER WHATSOEVER RELATING TO THE WEBSITE AND ANY INFORMATION, SOFTWARE, PRODUCTS, OR SERVICES PROVIDED HEREIN, INCLUDING WITHOUT LIMITATION THE WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE.</p>

                            <p>{siteMeta.nameShort} MAKES NO WARRANTY AS TO THE RESULTS THAT MAY BE ACHIEVED BY USING THE WEBSITE AND THE INFORMATION, SOFTWARE, PRODUCTS AND SERVICES ASSOCIATED WITH IT. USE OF THE WEBSITE OR THE SERVICE IS AT YOUR OWN RISK.</p>

                            <p>{siteMeta.nameShort} OR THE LENDER PARTNERS OR ADVERTISERS OR ANY OF THEIR OFFICERS, DIRECTORS, AGENTS, SUBSIDIARIES, AFFILIATES, EMPLOYEES, SUCCESSORS, ASSIGNS, CONTENT PROVIDERS, OR SERVICE PROVIDERS WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, OR PUNITIVE DAMAGES OR OTHER INJURY ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OF THE WEBSITE OR THE SERVICE OR WITH THE DELAY OR INABILITY TO USE THE WEBSITE, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, OR SERVICES OBTAINED THROUGH THE WEBSITE, OR OTHERWISE ARISING OUT OF THE USE OF THE WEBSITE, WHETHER RESULTING IN WHOLE OR IN PART, FROM BREACH OF CONTRACT, TORTIOUS BEHAVIOR, NEGLIGENCE, STRICT LIABILITY, OR OTHERWISE, EVEN IF {siteMeta.nameShort} OR THE LENDER PARTNERS HAVE BEEN ADVISED OF THE POSSIBILITY OF DAMAGES.</p>

                            <p>{siteMeta.nameShort} DOES NOT ENDORSE OR RECOMMEND THE PRODUCTS OF ANY PARTICULAR LENDER. {siteMeta.nameShort} IS NOT AN AGENT OF YOU OR ANY PARTICIPATING LENDER. {siteMeta.nameShort} IS NOT INVOLVED WITH THE LENDER’S USE OR REVIEW OF YOUR REQUEST FORM INFORMATION OR IN MAKING A DETERMINATION ABOUT WHETHER YOU MEET A PARTICULAR LENDER’S UNDERWRITING CRITERIA. THE LENDER IS SOLELY RESPONSIBLE FOR ITS SERVICES TO YOU, AND YOU AGREE THAT {siteMeta.nameShort} WILL NOT BE LIABLE FOR ANY DAMAGES OR COSTS OF ANY TYPE ARISING OUT OF OR IN ANY WAY CONNECTED WITH YOUR USE OF THE SERVICE. YOU UNDERSTAND THAT LENDER PARTNERS MAY KEEP YOUR REQUEST FORM INFORMATION, WHETHER OR NOT YOU ARE QUALIFIED FOR A LOAN WITH THEM.</p>

                            <p>SOME STATES DO NOT ALLOW THE LIMITATION OF LIABILITY AND DISCLAIMER OF IMPLIED WARRANTIES, SO THE DISCLAIMERS AND LIMITATIONS ABOVE MAY NOT APPLY TO YOU. IN SUCH STATES, THE RESPECTIVE LIABILITY OF {siteMeta.nameShort}, THE LENDER PARTNERS, AND THEIR OFFICERS, DIRECTORS, AGENTS, SUBSIDIARIES, AFFILIATES, EMPLOYEES, SUCCESSORS, ASSIGNS, CONTENT PROVIDERS AND SERVICE PROVIDERS IS LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW.</p>

                            <h4>Indemnity</h4>
                            <p>As a condition of using the Website or the Services, you agree to indemnify {siteMeta.nameShort} and the Lender Partners from and against any and all liabilities, expenses (including attorney’s fees) and damages arising out of claims resulting from your use of the Website, including any claims alleging facts that if true would constitute a breach by you of these Terms.</p>

                            <h4>Links to Third Parties</h4>
                            <p>The Website may contain links to other websites operated by Lender Partners or other third parties. These links are provided for your convenience and reference only. {siteMeta.nameShort} does not operate or control in any respect any information, software, products, or services available on such third party sites. {siteMeta.nameShort}’s inclusion of a link to a website does not imply any endorsement of the services or the site, its contents, or its sponsoring organization.</p>

                            <h4>Dispute Resolution</h4>
                            <p>Any claim or controversy arising out of or relating to the use of the Website, to the use of the Service, or to any acts or omission for which you may contend {siteMeta.nameShort} is liable (a “Dispute”), will be settled exclusively by binding arbitration in accordance with the commercial arbitration rules of the American Arbitration Association in effect at that time. The arbitration will be conducted in Broward County, Florida and judgment on the arbitration award may be entered into any court of competent jurisdiction. No arbitrator will have the power to award damages in connection with any Dispute in excess of actual compensatory damages and will not multiply actual damages or award consequential, punitive, or exemplary damages, and each party irrevocably waives any claim thereto. The agreement to arbitrate will not be construed as an agreement to the joinder or consolidation of arbitration under this agreement with arbitration of disputes or claims of any non-party, regardless of the nature of the issues of disputes involved.</p>

                            <p>BY AGREEING TO THESE TERMS, YOU GIVE UP YOUR RIGHT TO SETTLE ANY DISPUTES WITH {siteMeta.nameShort} IN A COURT OF LAW OR BEFORE A JURY. YOU ALSO GIVE UP YOUR RIGHT TO PARTICIPATE IN OR BRING CLASS ACTIONS. BY USING {siteMeta.nameShort}’S SERVICE, YOU CONSENT TO THESE RESTRICTIONS.</p>

                            <p>In the event the arbitration provisions herein become inapplicable or unenforceable, or in any instance of any lawsuit between you and {siteMeta.nameShort}, you agree that jurisdiction over and venue of any suit will be exclusively in the state and federal courts sitting in Broward County, Florida. You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the services must be filed within one year after such claim or cause of action arose or be forever barred.</p>

                            <h4>SMS Terms &amp; Conditions</h4>

                            <p>By providing a mobile phone number you are opting in to communications via SMS from {siteMeta.nameShort} and the Lender Partners. These communications may include a follow-up SMS message containing a link to your completed loan <Link to="/form/">Request Form</Link>, payment reminders from Lender Partners, and future offers.</p>

                            <p>For additional support: <a href={ "mailto:" + siteMeta.email }>{ siteMeta.email }</a></p>

                            <p>Message and Data Rates May Apply. Under no circumstances will {siteMeta.nameShort} be responsible for fees that your wireless carrier or other third parties may charge you for use of this service.</p>

                            <p>Messages may be delayed or undelivered for various factors. Carriers are not liable for delayed or undelivered messages.</p>

                            <h4>Other Terms</h4>

                            <p>These Terms constitute the entire agreement between you and {siteMeta.nameShort}. These Terms will be governed by the laws of Florida without regard to its conflict of law principles. If any part of these Terms is determined unenforceable pursuant to applicable law, then the invalid and unenforceable provision will be deemed superseded by a valid and enforceable provision that most closely connects the intent of the original provision and the remainder of these Terms will remain in effect. Any failure by {siteMeta.nameShort} to exercise or enforce any right or provision of these Terms will not be deemed a waiver of such right or provision. {siteMeta.nameShort} may amend these Terms at any time by posting the amended terms on its website. All amended terms are automatically effective immediately upon posting.</p>

                            <h4>Non-Waiver, Severability</h4>
                            <p>Any failure on behalf of {siteMeta.nameShort} to enforce these Terms and Conditions does not constitute our right to continue to enforce these terms and conditions. In the event that any of the terms and conditions are deemed to be illegal, invalid, or unenforceable, such terms and conditions will be nullified. Remaining terms and conditions will still be in full effect.</p>

                            <h4>Questions</h4>
                            <p>
                            <Link to="/contact/">Contact Us</Link>
                            <br />
                            <strong>
                                {siteMeta.address.street}
                                <br />
                                {siteMeta.address.street2}
                                <br />
                                {siteMeta.address.city}, {siteMeta.address.state} {siteMeta.address.zip}
                            </strong>
                            </p>
                        </div>
                    </div>
                </div>
            </PageWrapper>
        );
    }
};

TermsOfUse.childContextTypes = {
    siteMeta: PropTypes.object
};

export default TermsOfUse;

export const query = graphql`
    {
        pageImages: allFile(filter: {absolutePath: {regex: "^static/assets/images/covers/"}}) {
            edges {
                node {
                    id
                    absolutePath
                    image: childImageSharp {
                        fluid(
                            quality: 90, 
                            maxWidth: 2000,
                            traceSVG: { background: "#0ba7e2", color: "#0ba7e2" }
                        ) {
                            # base64
                            tracedSVG
                            aspectRatio
                            src
                            srcSet
                            srcWebp
                            srcSetWebp
                            sizes
                            originalImg
                            originalName
                            presentationWidth
                            presentationHeight
                        }
                    }
                }
            }
        }
    }
`;